<template>
  <div>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Regional Office Details">
          <validation-observer ref="basicValidation">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Office Location">
                    <validation-provider
                      #default="{ errors }"
                      name="Office Location"
                      rules="required"
                    >
                      <b-form-input
                        v-model="office.officeLocation"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Office Location"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Address">
                    <b-form-input
                      v-model="office.address"
                      placeholder="Office Address"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Phone">
                    <b-form-input v-model="office.phone" placeholder="Phone" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Fax">
                    <b-form-input
                      v-model="office.fax"
                      placeholder="Fax number"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Direction link">
                    <b-form-input
                      v-model="office.directionLink"
                      placeholder="Direction link"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-tab>
      <b-tab title="Hindi">
        <b-card title="Regional Office Details">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Office Location">
                  <b-form-input
                    v-model="office.translation.hi.officeLocation"
                    placeholder="Office Location"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Address">
                  <b-form-input
                    v-model="office.translation.hi.address"
                    placeholder="Office Address"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Phone">
                  <b-form-input
                    v-model="office.translation.hi.phone"
                    placeholder="Phone"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Fax">
                  <b-form-input
                    v-model="office.translation.hi.fax"
                    placeholder="Fax number"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Direction link">
                  <b-form-input
                    v-model="office.translation.hi.directionLink"
                    placeholder="Direction link"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <b-tab title="Marathi">
        <b-card title="Regional Office Details">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Office Location">
                  <b-form-input
                    v-model="office.translation.mr.officeLocation"
                    placeholder="Office Location"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Address">
                  <b-form-input
                    v-model="office.translation.mr.address"
                    placeholder="Office Address"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Phone">
                  <b-form-input
                    v-model="office.translation.mr.phone"
                    placeholder="Phone"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Fax">
                  <b-form-input
                    v-model="office.translation.mr.fax"
                    placeholder="Fax number"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Direction link">
                  <b-form-input
                    v-model="office.translation.mr.directionLink"
                    placeholder="Direction link"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-card>
      <b-col class="align-self-center">
        <b-button
          @click="createRegionalOffice"
          size="lg"
          block
          variant="primary"
          >Add Regional Office</b-button
        >
      </b-col>
    </b-card>

    <b-card title="Upload Bulk (CSV)">
      <b-form-file
        v-model="file"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />

      <b-card-text v-if="file" class="my-1">
        Selected file: <strong>{{ file ? file.name : "" }}</strong>
      </b-card-text>

      <div class="text-right">
        <b-button @click="uploadCSV" variant="primary">Upload File</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { getUserToken } from "@/auth/utils";

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BTabs,
  BTab,
  BFormGroup,
  BButton,
  BFormTextarea,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
import axios from "axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormFile,
    BCardText,
  },
  data() {
    return {
      required,
      file: [],
      uploadErrors: [],
      office: {
        officeLocation: "",
        address: "",
        phone: "",
        fax: "",
        directionLink: "",
        translation: {
          hi: {
            officeLocation: "",
            address: "",
            phone: "",
            fax: "",
            directionLink: "",
          },
          mr: {
            officeLocation: "",
            address: "",
            phone: "",
            fax: "",
            directionLink: "",
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    createRegionalOffice() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/org/regionalOffice/create`,
          this.office,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.makeToast(
            "success",
            "Success",
            `${response.data.officeLocation} has been created successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error while adding the  Data!");
        });
    },
    uploadCSV() {
      const csvFormData = new FormData();
      csvFormData.append("csv-data", this.file);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/org/regionalOffice/save`,
          csvFormData,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.data.length) {
            this.uploadErrors = response.data;
            this.makeToast(
              "danger",
              "Error",
              "Errors found while uploading data!"
            );
          } else {
            this.makeToast("success", "Success", `Uploaded successfully!`);
          }
        });
    },
  },
};
</script>

<style>
</style>